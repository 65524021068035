import React, {useRef} from "react";
import Image from "next/image";
import faucet from "../../public/assets/faucet.svg";
import zeniq from "../../public/assets/zeniq.png";
import Box from "../Box/Box";
import io from "socket.io-client";
import {useEffect, useState} from "react";
import playstore from '../../public/assets/playstore.svg'
import appstore from '../../public/assets/appstore.png'
import cross from '../../public/assets/cross-svgrepo-com.svg'
import Link from "next/link";
import { isMobile } from 'react-device-detect'
import help from '../../public/assets/help.svg'

const qrlink_static = 'https://zeniq.id/faucet.zeniq.net/backend/qrClickAuth?n=4883c515f63f75c8f3c742dadf7c6924&r=/backend/qrScanAuth&t=1&tETH&d=1&w=1'
// const qrlink_static = 'https://zeniq.id/192.168.50.48:8080/backend/qrClickAuth?n=4883c515f63f75c8f3c742dadf7c6924&r=/backend/qrScanAuth&t=1&tETH&d=1&w=1'
// const backend_ip = 'https://212.183.56.130:51041'
const backend_ip = 'https://faucet.zeniq.net'
// const backend_ip = 'http://localhost:8080'

const Faucet = () => {
    const [socket, setSocket] = useState(null);
    // const [qrlink, setQRLink] = useState();
    // const [qrlink, setQRLink] = useState(qrlink_static);
    const [qrlink, setQRLink] = useState('Connection Error!');
    const scanFun = useRef(() => {console.log('error')})
    const executeFun = useRef(() => {console.log('error')})
    const closingFun = useRef(() => {console.log('error')})
    const [tooltip, setTooltip] = useState()
    const [banner, setBanner] = useState(true)

    const setScanFun = (fun) => {
        scanFun.current = fun
    }
    const setExecuteFun = (fun) => {
        executeFun.current = fun
    }
    const setClosingFun = (fun) => {
        closingFun.current = fun
    }

    useEffect(() => {
        if (!socket) {
            const newSocket = io(backend_ip);
            setSocket(newSocket);
        }

        return () => {
            socket &&
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (socket) {
            // console.log(socket);
            socket.on("connect", () => {
                console.log("connected");
            });

            socket.on("disconnect", () => {
                console.log("disconnected");
            });

            socket.on("qr_code", data => {
                console.log('qr ', data)
                setQRLink(data)
            });

            socket.on("scan_success", data => {
                console.log('scanned ', data)
                scanFun.current()
            });

            socket.on("execute_success", () => {
                console.log('executed')
                executeFun.current()
            });

            socket.on("close", () => {
                console.log('close')
                closingFun.current()
            });
        }
    }, [socket, scanFun, executeFun]);

    return (
        <div id="Faucet">
            {qrlink && <>
            <div className="logo-container" onClick={() => {
                scanFun.current()
            }}>
                <div className="faucet-logo">
                    <Image src={faucet} layout={"fill"} priority={true}/>
                </div>
                <div className="zeniq-logo">
                    <Image src={zeniq} layout={"fill"} priority={true}/>
                </div>
            </div>
                {/*<FaucetImage/>*/}
                {/*<Shadow/>*/}
                <Box qrlink={qrlink} setScanFun={setScanFun} setExecuteFun={setExecuteFun} setClosingFun={setClosingFun}/>
            </>}
            <div className={"button-container " + (banner ? 'show-banner' : 'hide-banner')} onClick={() => {
                setBanner(false)
            }}>
                <Link href={'https://nomo.app/'} target={'_blank'}>
                    <div className='banner'>
                        <div>Check out the latest version of Nomo <sub>powered by ZENIQ</sub></div>
                        <a className="button" href={'https://nomo.app/'} target={'_blank'}>
                            <Image src={playstore} width={160} height={50}/>
                        </a>
                        <a className="button appstore" href={'https://nomo.app/'} target={'_blank'}>
                            <Image src={appstore} width={160} height={50}/>
                        </a>
                    </div>
                </Link>
                <div className="close" onClick={() => {
                    setBanner(false)}}>
                    <Image src={cross} width={20} height={20}/>
                </div>
            </div>

            <div className="logo">
                <Image src={zeniq} layout={"fill"} priority={true}/>
            </div>

            <div className="help" onClick={() => {isMobile && setShowTooltip(!showTooltip)}} onMouseEnter={() => {
                !isMobile && setTooltip(true)
            }} onMouseLeave={() => {
                !isMobile && setTooltip(false)
            }}>
                <Image src={help} layout={'fill'}/>
                { tooltip &&
                    <div className="tooltip">
                        <span>Welcome to the ZENIQ Faucet! Scan the QR Code on the box with your updated Nomo App and receive your ZENIQ Tokens!</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default Faucet;