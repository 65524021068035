import {NextSeo} from "next-seo";
import Content from "../components/Content/Content";
import Faucet from "../components/Faucet/Faucet.jsx";

export default function App() {
    return (
        <>
            <NextSeo
                title="ZENIQ Faucet"
                description="Get free ZENIQ tokens"
            />
            <Content>
                <Faucet/>
            </Content>
        </>
    );
}
