import React from 'react'
import QRCode from 'react-qr-code';

const QRCodeComponent = ({link}) => {
    return (
        <div id='QRCode'>
            <QRCode value={link} bgColor={'transparent'}  size={250}
                    // logoImage={'../../assets/zeniq.png'} logoWidth={250} logoOpacity={0.6} removeQrCodeBehindLogo={false}
            />
        </div>
        )
}

export default QRCodeComponent