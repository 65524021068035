import React, {useEffect, useRef, useState} from 'react'
import zeniq from '../../public/assets/zeniq.png'
import Image from 'next/image'
import QRCodeComponent from "../QRCode/QRCode";
import Confetti from 'react-confetti'
// import aa from '../../public/assets/aa.mp3'


const Box = ({qrlink, setScanFun, setExecuteFun, setClosingFun}) => {
    const [state, setState] = useState('falling')
    const state_ref = useRef('falling')

    const onScan = () => {
        console.log('onScan ', state)
        if (state === 'fallen' || state === 'falling') {
            state_ref.current = 'scanned'
            setState('scanned')
            setTimeout(() => {
                console.log('here ', state_ref)
                if(state_ref.current === 'scanned'){
                    console.log('timeout')
                    state_ref.current = 'closing'
                    setState('closing')
                }
            }, 10000)
        }
    }

    const onClose = () => {
        setState('closing')
    }

    const onExecute = () => {
        console.log('onExecute ', state)
        setState('executed')
        setTimeout(() => {
            const sound = document.getElementById('aa');
            sound.play()
            sound.pause();
            sound.currentTime = 0;
            setState('closing')
        }, 3000)
    }

    useEffect(() => {
        setScanFun(onScan)
        setExecuteFun(onExecute)
        setClosingFun(onClose)
        setTimeout(() => {
            setState('fallen')
        }, 1500)
    }, [])

    return (
        <div id='Box' className={state} onClick={() => {
            switch (state) {
                case 'falling':
                    break;
                case 'fallen' :
                    // onScan()
                    window.open(qrlink, '_blank').focus();
                    break;
                case 'scanned' :
                    // onExecute()
                    // window.open(qrlink, '_blank').focus();
                    break;
                case 'closing' :
                    // onScan()
                    // window.open(qrlink, '_blank').focus();
                    break;
            }
        }}>
            {state === 'executed' ?
                <Confetti
                    width={850}
                    height={5000}
                    confettiSource = {{
                        x: 380,
                        y: 1700,
                        w: 150,
                        h: 0
                    }}
                    gravity={-0.2}
                    numberOfPieces = {150}
                    colors = {['#d8be8a', '#696d6f', '#8d6a20', '#d8be8a', '#d8be8a', '#ffffff']}
                /> : ''}
            <div className="top1">
                <div className="open1"></div>
            </div>
            <div className="top2">
                <div className="open2"></div>
            </div>
            <div className="bottom">
                <div id="shadow"></div>
            </div>
            <div className="front">
                <QRCodeComponent link={qrlink}/>
            </div>
            <div className="left"></div>
            <div className="right">
                <Image src={zeniq} layout={'fill'}/></div>
            <div className="back">
                <QRCodeComponent link={qrlink}/>
            </div>
            <audio id={'aa'} controls>
                {/*<source src={'../../assets/aa.mp3'} type="audio/mp3"/>*/}
                <source src={'../../assets/coin.wav'} type="audio/wav"/>
            </audio>
            {/*<div style={{position: 'absolute', top: '-200px'}}>{state}</div>*/}
        </div>
    )
}

export default Box